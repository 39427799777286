import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "gatsby";
import axios from 'axios';
import { OutboundLink } from "gatsby-plugin-google-gtag";

import { PageLayout } from "../layouts";
import { GetStartedBlock, AboutPartieBlock, EventsGrid, Block } from "../components";

import EventImg from "../images/razer-gold/razer-gold.svg";
import PromoBgXs from "../images/razer-gold/Partie-Giveaway-Xs.svg";
import PromoBgSm from "../images/razer-gold/Partie-Giveaway-Sm.svg";
import PromoBgMd from "../images/razer-gold/Partie-Giveaway-Md.svg";
import PromoBgLg from "../images/razer-gold/Partie-Giveaway-Lg.svg";
import PromoBgXl from "../images/razer-gold/Partie-Giveaway-Xl.svg";
import "../global/styles/page-styles/razergold-page.scss";

const API_ENDPOINT = process.env.API_ENDPOINT;
const API_ENDPOINT_AVATAR_LIST = process.env.API_ENDPOINT_AVATAR_LIST;

const RazerGold = () => {
    const [pageData, setPageData] = useState({});
    const gameId = null;
    const pageSize = 6;
    const bounty = "bounty";
    const userId = null;
    /**
     *
     * Get Event Data when the component mounts.
     *
     */
    useEffect(() => {
        /**
         * 
         * Hyrdate Events Grid data.
         * Pass in Search Queries as query params.
         * 
         */
        axios.get(`${API_ENDPOINT}?pageSize=${pageSize}&roomType=${bounty}&userId=${userId}`)
            .then((res) => {
                for (let i = 0; i < res.data.length; i++) {
                    axios.get(`${API_ENDPOINT_AVATAR_LIST}?roomId=${res.data[i].id}`)
                        .then((res2) => {
                            res.data[i].people = res2.data
                            setPageData({
                                events: res.data
                            });
                        })
                        .catch(error => {
                            console.log('Fetch error :: ', error);
                        })
                }
            })
            .catch(error => {
                console.log('Fetch error :: ', error);
            })
    }, []);

    return (
        <>
            <Helmet>
                <title>Partie | Razer Gold</title>
                <html lang="en" />
                <meta name="description" content="Enjoy an enhanced Partie experience with access to Bounties, Tournaments, Clubs And Favors, only available with Partie Plus</Link>." />
            </Helmet>
            <PageLayout className="razergold-page" hero>
                <Block hero lazy>
                    <div className="partie-block__column">
                        <h2 className="partie-block__title">
                            Subscribe with Razer Gold
                        </h2>
                        <p className="partie-block__description">
                            Subscribe to <Link className="terms-link" to="/plus">Partie Plus</Link> using Razer Gold and Get $2 off of your first month!
                        </p>
                        <p className="partie-block__description">
                            Enjoy an enhanced Partie experience with access to Bounties, Tournaments, Clubs And Favors,
                            only available with <Link className="terms-link" to="/plus">Partie Plus</Link>.
                        </p>
                        <div className="partie-block__cta">
                            <OutboundLink className="btn" href="https://app.partie.com/">
                                Join now
                            </OutboundLink>
                            <Link className="terms-link" to="/razergold/terms">
                                View Terms &amp; Conditions
                            </Link>
                        </div>
                    </div>
                    <div className="partie-block__column">
                        <img src={EventImg} alt="Razer Gold" />
                    </div>
                </Block>
                <AboutPartieBlock />
                <Block className="razer-promo-block">
                    <Link to="/giveaways/razergold">
                        <picture>
                            <source media="(min-width: 1600px)" srcSet={PromoBgXl} />
                            <source media="(min-width: 1200px)" srcSet={PromoBgLg} />
                            <source media="(min-width: 1024px)" srcSet={PromoBgMd} />
                            <source media="(min-width: 768px)" srcSet={PromoBgSm} />
                            <img
                                src={PromoBgXs}
                                loading="lazy"
                                alt="Razer Promotion Block"
                                aria-hidden
                                className="promo-block__bg"
                            />
                        </picture>
                    </Link>
                </Block>
                <Block centered className="events-block">
                    <h2 className="events-block__title">Bounties Powered</h2>
                    <h2 className="events-block__title">by Razer Gold</h2>
                    <EventsGrid events={pageData.events} length={pageSize} />
                </Block>
                <GetStartedBlock />
            </PageLayout>
        </>
    );
};

export default RazerGold;